window.countdown = function () {
    return {
        interval: false,
        hours: 23, mins: 59, secs: 60,
        elhours: false, elmins: false, elsecs: false,
        init() {
            this.elsecs = document.getElementById('countdown-secs');
            this.elmins = document.getElementById('countdown-mins');
            this.elhours = document.getElementById('countdown-hours');

            window.countdown = this;

            this.tick();
            this.start();
        },
        start() {
            this.interval = setInterval(() => {
                window.countdown.tick();
            }, 1000);
        },
        stop() {
            clearInterval(this.interval);
        },
        tick() {
            this.secs--;

            if(this.secs >= 0)
                this.secs;
            else
            {
                this.secs = 59;
                this.mins--;
            }

            if(this.mins < 0)
            {
                this.mins = 59;
                this.hours--;
            }

            if(this.hours < 0)
            {
                this.secs = 0;
                this.min = 0;
                this.hours = 0;

                this.stop();
            }

            this.elsecs.style.setProperty('--value', this.secs)
            this.elmins.style.setProperty('--value', this.mins)
            this.elhours.style.setProperty('--value', this.hours)
        }
    }
}