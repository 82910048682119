import $,
{ each } from 'jquery';

/*
Add <meta mode="demo"> to active demo mode
*/

const url = new URL(window.location.href);

if(url.protocol == 'file:')
{
    $('.shield-mask-1').removeClass('shield-mask-1');
    $('.shield-mask-bg-1').removeClass('shield-mask-bg-1');
}

window.dev = {};
window.dev.attrs = false;

if (url.href.indexOf("https://imac.local:5757") >= 0)
{
    if (document.head.querySelector("[mode~=demo]") === null)
    {
        const navs =
        {
            'Live': 'https://adquiz.pages.dev',
            'Tailwind': 'https://tailwindcss.com/docs/customizing-colors',
            'Shades': 'https://www.tailwindshades.com',
            'DaisyUI': 'https://daisyui.com/components/button/',
            'Flowbite': 'https://flowbite.com/docs/components/carousel/',
            'Alpine': 'https://alpinejs.dev/start-here',
            'AnimeJS': 'https://animejs.com/documentation/',
            'System': 'https://bigwinfc.com/',
            'OG1': 'https://www.youtube.com/watch?v=fktEpjFcGFs&list=RDCMUC_mxFC9SjHEBFq0r_YKER-w&index=2',
            'OG2': 'https://www.youtube.com/watch?v=IGTFQnVr5GE',
            'Spin': 'https://www.luckslots789.xyz/Games/Fortune+Ox/index.html',
            'PNG1': 'https://www.pngwing.com',
            'PNG2': 'https://www.pngaaa.com',
            'PNG3': 'https://toppng.com',
            'CODEPEN': 'https://codepen.io/trending',
            'CONFETTI': 'https://github.com/loonywizard/js-confetti',
            'PARTICLE': 'https://particles.js.org',
        };

        let devnav = '<div class="absolute py-3 bg-opacity-50 w-full top-0 left-0 z-50 text-gray-400 text-xs opacity-10 transition-opacity hover:opacity-100"><div class="block max-w-5xl text-center mx-auto">';

        $.each(navs, function (key, value)
        {
            devnav += '<div class="inline-block"><a class="transition-all p-2 hover:text-red-400" href="' + value + '" target="_blank">' + key + '</a></div>';
        });

        devnav += '</div></div>';

        $("html > body").append(devnav);

        $('#loading').remove();

        const dev = $('meta[name~=dev]');

        if (dev.length > 0)
        {
            window.dev.attrs = {};

            dev.each(function ()
            {
                $.each(this.attributes, function ()
                {
                    const is_numeric = new RegExp('^[0-9]+$');

                    if (this.specified && this.name != 'name')
                    {
                        this.value = this.value.trim();
                        window.dev.attrs[this.name] = is_numeric.test(this.value) ? Number(this.value) : this.value;
                    }
                });
            });
        }

        console.log('[Dev Demo]', false);
    }
    else
    {
        console.log('[Dev Demo]', true);
    }

    console.log('[Dev Para]', window.dev.attrs);
    window.settings_name = "example-settings.json";
}
else
{
    window.dev = false;
    window.settings_name = "settings.json";
}

$('meta[name~=dev]').remove();
$('meta[mode~=demo]').remove();