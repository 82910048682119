import $, { each } from 'jquery';
import anime from 'animejs';

export default class SceneResult
{
    constructor(init = true)
    {
        if (typeof (window.scenes) == "undefined")
            window.scenes = {}

        window.scenes.result = this;

        this.tooshort = this.isShort();

        if(init)
            this.init();
        else if(this.tooshort)
            $('.shield-logo-container').addClass('opacity-0');
    }
    play(answer = 0)
    {
        $('.result-inner').addClass('hidden');

        $('#result-'+answer).removeClass('hidden');

        $('#result').addClass('duration-300');
        $('#result').removeClass('pointer-events-none');
        $('#result').removeClass('bg-opacity-0');

        $('#result > div').addClass('duration-300');
        $('#result > div').removeClass('opacity-0');

        this.fadein.play();
        this.fadeout.play();

        if(this.tooshort)
        {
            $('#intro').removeClass('z-40').addClass('z-10');
            this.logofadeout.play();
        }

        if(!$('body').hasClass('screen-2xs'))
            tsParticles.load('tsp-side', this.preset());
    }
    init()
    {
        $('#result').addClass('pointer-events-none');
        $('#result').addClass('bg-opacity-0');
        $('#result > div').addClass('opacity-0');

        this.fadein = anime({
            targets: '.result-container',
            direction: 'reverse',
            delay: 750,
            duration: 750,
            scale: 1.2,
            translateY: -65,
            autoplay: false,
            easing: 'easeInBounce',
            complete: function (anim)
            {
                this.remove();
            }
        });

        this.fadeout = anime({
            targets: '.intro-message, #quiz',
            opacity: 0,
            duration: 1000,
            autoplay: false,
            complete: function (anim)
            {
                this.remove();
                $('.intro-message, .shield-container, #quiz').remove();
            }
        });

        if(this.tooshort)
        {
            this.logofadeout = anime({
                targets: '.shield-logo-container',
                opacity: 0,
                duration: 500,
                autoplay: false,
                easing: 'easeInOutExpo',
                complete: function (anim)
                {
                    console.log('!!!!');
                    this.remove();
                }
            });
        }
    }
    isShort()
    {
        const logo = $('.shield-logo');
        const container = $('.result-container');

        const lbounding = logo[0].getBoundingClientRect();
        const cbounding = container[0].getBoundingClientRect();

        const lpos = Math.round(40 + lbounding.height);
        const cpos = Math.round(cbounding.y - 20);

        return lpos > cpos;
    }
    completed()
    {
        window.dispatchEvent(new Event('scenes-result-completed'));
    }
    preset()
    {
        return {
            "autoPlay": true,
            "fullScreen": {
                "zIndex": 20
            },
            "emitters": [
                {
                    "position": {
                        "x": 0,
                        "y": 0
                    },
                    "rate": {
                        "quantity": 1,
                        "delay": 0.3
                    },
                    "size": {
                        "width": 0,
                        "height": 40
                    },
                    "particles": {
                        "move": {
                            "direction": "top-right",
                            "outModes": {
                                "top": "none",
                                "default": "destroy"
                            }
                        }
                    }
                },
                {
                    "position": {
                        "x": 100,
                        "y": 0
                    },
                    "rate": {
                        "quantity": 1,
                        "delay": 0.3
                    },
                    "size": {
                        "width": 0,
                        "height": 40
                    },
                    "particles": {
                        "move": {
                            "direction": "top-left",
                            "outModes": {
                                "top": "none",
                                "default": "destroy"
                            }
                        }
                    }
                }
            ],
            "particles": {
                "shape": {
                    "type": "image",
                    "options": {
                        "image": [
                            {
                                "src": "img/coin-3-md.png",
                                "width": 24,
                                "height": 24,
                                "particles": {
                                    "size": {
                                        "value": 8
                                    }
                                }
                            },
                        ]
                    }
                },
                "move": {
                    "enable": true,
                    "gravity": {
                        "enable": true,
                    },
                    "speed": {
                        "min": 5,
                        "max": 10
                    },
                    "direction": "top",
                    "outModes": {
                        "default": "destroy",
                        "top": "none"
                    }
                },
                "number": {
                    "value": 0
                },
                "opacity": {
                    "value": 1
                },
                "rotate": {
                    "value": {
                        "min": 0,
                        "max": 360
                    },
                    "direction": "random",
                    "animation": {
                        "enable": true,
                        "speed": 30
                    }
                },
                "tilt": {
                    "direction": "random",
                    "enable": true,
                    "value": {
                        "min": 0,
                        "max": 360
                    },
                    "animation": {
                        "enable": true,
                        "speed": 30
                    }
                },
                "size": {
                    "value": {
                        "min": 0,
                        "max": 2
                    },
                    "animation": {
                        "enable": true,
                        "startValue": "min",
                        "count": 1,
                        "speed": 16,
                        "sync": true
                    }
                },
                "roll": {
                    "darken": {
                        "enable": true,
                        "value": 25
                    },
                    "enable": true,
                    "speed": {
                        "min": 5,
                        "max": 15
                    }
                },
                "wobble": {
                    "distance": 30,
                    "enable": true,
                    "speed": {
                        "min": -7,
                        "max": 7
                    }
                }
            }
        };
    }
}