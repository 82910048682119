import $, { each } from 'jquery';
import anime from 'animejs';

import GoldCoins from '../gold-coins';

export default class SceneIntro
{
    constructor(init = true)
    {
        if(typeof(window.scenes) == "undefined")
            window.scenes = {}

        window.scenes.intro = this;

        if(init)
            this.init();

        // $('#quiz').remove();
        // $('#intro').removeClass('pointer-events-none');
    }
    play()
    {
        this.shield.fadein.play();
        this.logo.fadein.play();
    }
    skip()
    {
        $('.logo-text-container').css('top', '0px');
        $('.shield-container').remove();
        
        $('.intro-message')
            .addClass('fixed')
            .removeClass('relative')
            .css('bottom', '0px');

        $('.shield-logo-container')
            .removeClass('py-0')
            .removeClass('transition-all')
            .removeClass('duration-1000')
            .removeClass('delay-1000')
            .addClass('py-5');
    }
    init()
    {
        $('.intro-message').addClass('fixed').removeClass('relative');

        this.initMessage();

        this.message = {};
        this.shield = {};
        this.logo = {};

        $('.shield-coins').addClass('opacity-0');

        this.shield.fadein = anime({
            targets: '.shield-model',
            direction: 'reverse',
            scale: 0.9,
            translateY: 50,
            opacity: 0,
            autoplay: false,
            easing: 'easeInOutExpo',
            complete: function (anim) {
                this.remove();
            }
        });

        this.shield.fadeout = anime({
            targets: '.shield-container',
            scale: 0.9,
            delay: 750,
            duration: 1500,
            opacity: 0,
            autoplay: false,
            easing: 'easeInOutExpo',
            complete: function (anim) {
                this.remove();
                window.scenes.intro.completed();
            }
        });

        this.logo.fadein = anime({
            targets: '.shield-logo',
            direction: 'reverse',
            scale: 1.1,
            endDelay: 200,
            opacity: 0,
            autoplay: false,
            easing: 'easeInOutExpo',
            begin: function(anim) {
                $('.shield-coins').removeClass('opacity-0');
                anime({
                    targets: '.shield-coins',
                    direction: 'reverse',
                    scale: 1.1,
                    duration: 2500,
                    opacity: 0,
                    easing: 'easeInOutExpo',
                    complete: function (anim) {
                        this.remove();
                    }
                });
            },
            complete: function (anim) {
                this.remove();
                window.scenes.intro.message.fadein.play();
            }
        });

        this.logo.totop = anime({
            targets: '.logo-text-container',
            top: 0,
            delay: 500,
            duration: 2000,
            autoplay: false,
            easing: 'easeInOutExpo',
            begin: function(anim) {
                $('.shield-logo-container').removeClass('py-0').addClass('py-5');
            },
            complete: function (anim) {
                this.remove();
                $('.shield-logo-container').removeClass('transition-all').removeClass('duration-1000').removeClass('delay-1000');
            }
        });
        
        this.message.fadein = anime({
            targets: '.intro-message',
            direction: 'reverse',
            scale: 2,
            opacity: 0,
            autoplay: false,
            easing: 'easeInBounce',
            begin: function(anim) {
                setTimeout(function(){
                    new GoldCoins('tsp-explosions', 95);
                }, 300);
            },
            complete: function (anim) {
                this.remove();
                window.scenes.intro.message.tobottom.play();
                window.scenes.intro.logo.totop.play();
                window.scenes.intro.shield.fadeout.play();
            }
        });
        
        this.message.tobottom = anime({
            targets: '.intro-message',
            bottom: 0,
            marginTop: 0,
            delay: 500,
            duration: 2000,
            autoplay: false,
            easing: 'easeInOutExpo',
            complete: function (anim) {
                this.remove();
            }
        });
    }
    initMessage()
    {
        const winh = $(window).height();
        const scrt = $(window)['scrollTop']();

        const logo = $('.shield-logo');
        const bounding = logo[0].getBoundingClientRect();
        const pos = Math.round(bounding.y);

        $('.intro-message').css('bottom', Math.round(winh - bounding.bottom - $('.intro-message').outerHeight()) + 'px');
    }
    completed()
    {
        window.dispatchEvent(new Event('scenes-intro-completed'));
    }
}