import $, { each } from 'jquery';

// import InitSettings from './init-settings';

import SceneIntro from './scenes/intro';
import SceneQuestion from './scenes/questions';
import SceneResult from './scenes/result';

window.maincontroller = function ()
{
    return {
        anime: false,
        init()
        {
            window.maincontroller = this;
        },
        startAnimation()
        {
            this.scenes.intro.play();
        },
        openCloak()
        {
            $('#preload-images').remove();
            $('#loading').addClass('opacity-0');
            $('#main').removeClass('fixed hidden');

            setTimeout(() => {
                window.dispatchEvent(new Event('app-ready'));
            }, 250);
        },
        updateResponsive()
        {
            const width = $( window ).width();

            $('body').removeClass('screen-2xl').removeClass('screen-xl').removeClass('screen-lg').removeClass('screen-md').removeClass('screen-sm').removeClass('screen-xs').removeClass('screen-2xs');
        
            if(width <= 360)
                $('body').addClass('screen-2xs')
            else if(width <= 480)
                $('body').addClass('screen-xs')
            else if(width <= 640)
                $('body').addClass('screen-sm')
            else if(width <= 768)
                $('body').addClass('screen-md')
            else if(width <= 1024)
                $('body').addClass('screen-lg')
            else if(width <= 1280)
                $('body').addClass('screen-xl')
            else
                $('body').addClass('screen-2xl')
        },
        preAllImageLoaded()
        {
            this.updateResponsive();
            window.dispatchEvent(new Event('all-images-loaded'));
        },
        checkIfAllImagesLoaded()
        {
            window.loaded_images = 0;
            window.total_images = $("img").length;

            if(window.total_images <= 0)
            {
                this.preAllImageLoaded();
            }
            else
            {
                $("img").each(function (idx, img) {
                    $("<img>").on("load", () => {
                        if (++window.loaded_images >= total_images) {
                            window.maincontroller.preAllImageLoaded();
                        }
                    }).attr("src", $(img).attr("src"));
                });
            }
        },
        initScenes() {
            if (window.dev.attrs)
            {
                switch (window.dev.attrs.scene)
                {
                    case 2:
                            
                        this.scenes = {
                            'intro': new SceneIntro(false),
                            'question': new SceneQuestion(false),
                        };

                        this.scenes.intro.skip();
                        this.scenes.question.initTyped();
                        this.scenes.question.typing();

                        $('#result').remove();

                        break;
                    case 3:

                        this.scenes = {
                            'intro': new SceneIntro(false),
                            'result': new SceneResult(false),
                        };

                        this.scenes.intro.skip();

                        $('#quiz').remove();
                        $('.intro-message').remove();

                        break;
                
                    default:
                        this.anime = true;

                        this.scenes = {
                            'intro': new SceneIntro(),
                            'question': new SceneQuestion(),
                            'result': new SceneResult(),
                        };
                        break;
                }
            }
            else
            {
                this.anime = true;

                this.scenes = {
                    'intro': new SceneIntro(),
                    'question': new SceneQuestion(),
                    'result': new SceneResult(),
                };
            }
        },
        maincontroller:
        {
            ['x-on:DOMContentLoaded.window']()
            {
                this.checkIfAllImagesLoaded();
            },
            // ['x-on:settings-loaded.window']()
            // {
            //     if(window.dev)
            //     {
            //         console.log('[Dev Settings]', window.settings_name);
            //         console.log(window.settings);
            //     }

            //     new InitSettings(window.settings);
            // },
            ['x-on:settings-inited.window']()
            {
                this.checkIfAllImagesLoaded();
            },
            ['x-on:resize.window']()
            {
                this.updateResponsive();
            },
            ['x-on:all-images-loaded.window']()
            {
                this.images_loaded = true;
                this.initScenes();

                this.openCloak();
            },
            ['x-on:app-ready.window']()
            {
                if(!this.anime)
                    return;

                this.startAnimation();
            },
            ['x-on:scenes-intro-completed.window']()
            {
                if(!this.anime)
                    return;

                this.scenes.question.play();
            },
            ['x-on:answered'](event)
            {
                if(!this.anime)
                    return;

                this.scenes.result.play(event.detail.answer);
            },
        }
    }
}