import $, { each } from 'jquery';
import anime from 'animejs';
import Typed from 'typed.js';

export default class SceneQuestion
{
    constructor(init = true)
    {
        if(typeof(window.scenes) == "undefined")
            window.scenes = {}

        window.scenes.question = this;

        if(init)
            this.init();
    }
    play()
    {
        this.fadein.play();
        this.typing();
    }
    init()
    {
        const targets = '.country-flag, .question-text-container, .choices .choice';
        const stagger_duration = 200;

        this.initTyped();

        $('#quiz').addClass('pointer-events-none');

        setTimeout(() => {
            $('#quiz').removeClass('pointer-events-none');
        }, ($(targets).length + 1) * stagger_duration * 2);

        this.fadein = anime({
            targets: targets,
            direction: 'reverse',
            opacity: 0,
            translateY: 65,
            autoplay: false,
            easing: 'easeInOutSine',
            delay: anime.stagger(stagger_duration, { direction: 'reverse', start: 500 }),
            complete: function (anim) {
                this.remove();
            }
        });
    }
    initTyped()
    {
        const text = $('.question-text');
        const container = text.closest('.flex-1');

        container.css('height', container.outerHeight() + 'px');

        this.questiontext = text.html();
        text.html('');
    }
    typing()
    {
        new Typed('.question-text', {
            strings: [this.questiontext],
            typeSpeed: 40
        });
    }
    completed()
    {
        window.dispatchEvent(new Event('scenes-question-completed'));
    }
}