import $, { each } from 'jquery';
import Alpine from 'alpinejs';

// import counterUp from 'counterup2';
// import 'waypoints/lib/noframework.waypoints.min.js';
// import 'waypoints/lib/shortcuts/inview.js';

import './dev';
import './maincontroller';
import './countdown.js';
// import './confetti';
// import './activatablenav';

// import './promos';
// import './promomodal';

window.$ = $;
window.Alpine = Alpine;

Alpine.start();

window.dispatchEvent(new Event('settings-loaded'));