import $, { each } from 'jquery';

export default class GoldCoins
{
    constructor(id, offet = 100)
    {
        this.container = id;

        const options = this.moveEmitterTo(".free-credit", this.preset(), offet);

        tsParticles.load(this.container, options);
        this.explosion = tsParticles.domItem(0);
    }
    moveEmitterTo(query, options, offet = 100)
    {
        const winw = $(window).width();
        const winh = $(window).height();

        const srcl = $(window)['scrollLeft']();
        const scrt = $(window)['scrollTop']();

        const bounding = $(query)[0].getBoundingClientRect();

        const pl = Math.round(bounding.left + srcl);
        const pr = Math.round(bounding.right + srcl);
        const pt = Math.round(bounding.top + scrt);
        const pb = Math.round(bounding.bottom + scrt);
        const elw = pr - pl;
        const elh = pb - pt;
        const posx = elw/2 + pl;
        const posy = elh/2 + pt;

        options.emitters.position = {
            "x": Math.round(posx/winw*offet),
            "y": Math.round(posy/winh*100),
        };

        options.emitters.size = {
            "width": Math.round(elw/winw*40),
            "height": Math.round(elh/winw*10)
        }

        options.autoPlay = true;

        return options;
    }
    explode()
    {
    }
    preset()
    {
        return {
            "autoPlay": false,
            "duration": 5,
            "fullScreen": {
                "zIndex": 1
            },
            "emitters": {
                "life": {
                    "count": 1,
                    "duration": 0.1,
                    "delay": 0.3
                },
                "rate": {
                    "delay": 0.1,
                    "quantity": 25
                },
                "position": {
                    "x": 50,
                    "y": 50
                },
                "size": {
                    "width": 20,
                    "height": 2
                }
            },
            "particles": {
                "number": {
                    "value": 0
                },
                "shape": {
                    "type": "image",
                    "options": {
                        "image": [
                            {
                                "src": "img/coin-3-md.png",
                                "width": 24,
                                "height": 24,
                                "particles": {
                                    "size": {
                                        "value": 8
                                    }
                                }
                            },
                        ]
                    }
                },
                // "shape": {
                //     "type": "circle",
                //     "options": {}
                // },
                // "opacity": {
                //     "value": 1,
                //     "animation": {
                //         "enable": true,
                //         "minimumValue": 0,
                //         "speed": 2,
                //         "startValue": "max",
                //         "destroy": "min"
                //     }
                // },
                "size": {
                    "value": 8,
                    "random": {
                        "enable": true,
                        "minimumValue": 6
                    }
                },
                "life": {
                    "duration": {
                        "sync": true,
                        "value": 5
                    },
                    "count": 1
                },
                "move": {
                    "enable": true,
                    "gravity": {
                        "enable": true,
                    },
                    "speed": {
                        "min": 1,
                        "max": 5
                    },
                    "direction": "none",
                    "outModes": {
                        "default": "destroy",
                        "top": "none"
                    }
                },
                "rotate": {
                    "value": {
                        "min": 0,
                        "max": 360
                    },
                    "direction": "random",
                    "move": true,
                    "animation": {
                        "enable": true,
                        "speed": 60
                    }
                },
                "tilt": {
                    "direction": "random",
                    "enable": true,
                    "move": true,
                    "value": {
                        "min": 0,
                        "max": 360
                    },
                    "animation": {
                        "enable": true,
                        "speed": 60
                    }
                },
                "roll": {
                    "darken": {
                        "enable": true,
                        "value": 25
                    },
                    "enable": true,
                    "speed": {
                        "min": 15,
                        "max": 25
                    }
                }
            }
        };
    }
}